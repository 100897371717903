import React, { useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import navlogo from '../assetr/navlogo.png';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

export default function Navbar() {
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const isActive = (pathname) => location.pathname + location.search === pathname;

    const handleChangeLanguage = useCallback((selectedOption) => {
        const lang = selectedOption.value;
        i18n.changeLanguage(lang);
        localStorage.setItem('i18nextLng', lang);
      }, [i18n]);

    useEffect(() => {
        const pathToTitleMap = {
            '/?goto=home': 'MANA | ' + t('Home'),
            '/?goto=about': 'MANA | ' + t('AboutUs'),
            '/products': 'MANA | ' + t('Catalogue'),
            '/?goto=contact': 'MANA | ' + t('ContactUs')
        };

        const newTitle = pathToTitleMap[location.pathname + location.search] || 'MANA | ' + t('Home');
        document.title = newTitle;
    }, [location.pathname, location.search, t]);

    return (
        <div>
            <div className="container-fluid p-0 bg-colornav">
                <div className="container">
                    <nav className="navbar navbar-expand-lg fixed-top bg-white">
                        <div className="container">
                            <Link className="navbar-brand" to='/'>
                                <img src={navlogo} width={150} alt="Example" />
                            </Link>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className={`nav-link ${isActive('/?goto=home') ? 'active' : ''}`} to='/?goto=home'>{t('Home')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${isActive('/?goto=about') ? 'active' : ''}`} to='/?goto=about'>{t('AboutUs')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ps-lg-3 ${isActive('/products') ? 'active' : ''}`} to='/products'>{t("Catalogue")}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${isActive('/?goto=contact') ? 'active' : ''}`} to='/?goto=contact'>{t('ContactUs')}</Link>
                                    </li>
                                </ul>
                                <div>
                                    <LanguageSelector handleChangeLanguage={handleChangeLanguage} />
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}
