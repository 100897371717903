import React from 'react'
import SayTesTimonial from './SayTestimonial'
import { t } from 'i18next'
import testimonialData from '../mockApi/testimonials.json';

const Testimonial = () => {
  return (
    <>
    {testimonialData?.length>=2 &&

    <section id='testimonial'>
      <div className="container mt-5">
      
      <div className='text-center'>
      <h3 className='font-inter text-bigliter text-colored'>{t("Testimonial")}</h3>
        </div>
        <SayTesTimonial />
      </div>
    </section>
    } 
    </>
  
  )
}

export default Testimonial