import './App.css'
import { Route, Routes } from 'react-router-dom';
import Index from './page/Index';
import Product from './page/Product';
import Impressum from './page/Impressum';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;



function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Index />} />
        <Route path='/products' element={<Product />} />
        <Route path='/impressum' element={<Impressum />} />
      </Routes>
    </>
  );
}

export default App;
