import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enLocales from "./en";
import deLocales from "./de";
import hrLocales from "./hr";


// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      de: { translations: deLocales },
      hr: { translations: hrLocales },
    },
    lng: localStorage.getItem("i18nextLng") || "de",

    ns: ["translations"],
    defaultNS: "translations",
    loadPath: "/locales/{{lng}}/{{ns}}.json?v=" + new Date().getTime(), // add timestamp to loadPath
    fallbackLng: "de",
    debug: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
