import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Cookies from 'js-cookie';

const messages = {
  en: {
    message: "This website uses cookies to enhance the user experience.",
    accept: "Accept",
    decline: "Decline"
  },
  de: {
    message: "Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.",
    accept: "Akzeptieren",
    decline: "Ablehnen"
  },
  hr: {
    message: "Ova web stranica koristi kolačiće za poboljšanje korisničkog iskustva.",
    accept: "Prihvati",
    decline: "Odbij"
  }
};

const getBrowserLanguage = () => {
  const lang = navigator.language.split('-')[0];
  return messages[lang] ? lang : 'de';
};

const CookieConsentModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [language, setLanguage] = useState('de');

  useEffect(() => {
    const lang = getBrowserLanguage();
    setLanguage(lang);

    const consent = Cookies.get('cookieConsent');
    if (!consent) {
      setModalIsOpen(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setModalIsOpen(false);
  };

  const handleDecline = () => {
    Cookies.set('cookieConsent', 'declined', { expires: 365 });
    setModalIsOpen(false);
  };

  const { message, accept, decline } = messages[language];

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleDecline}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center'
        }
      }}
    >
      <div>
        <p>{message}</p>
        <button onClick={handleAccept} style={{ background: "#4CAF50", color: "white", fontSize: "13px", marginRight: "10px" }}>
          {accept}
        </button>
        <button onClick={handleDecline} style={{ background: "#f44336", color: "white", fontSize: "13px" }}>
          {decline}
        </button>
      </div>
    </Modal>
  );
};

export default CookieConsentModal;
