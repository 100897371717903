import React, { useState } from 'react';
import defaultImage from '../assetr/default.png'
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
const MyComponent = ({ image, slug,categoryName,selectedCategory }) => {

    const navigate = useNavigate();
    const [cardImage, setCardImage] = useState(image);
    const [isButtonShow, setButtonShow] = useState(false);
    console.log(selectedCategory);

    const handleMouseOver = () => {
        setButtonShow(true);
    };
    const handleMouseOut = () => {
        setButtonShow(false);
    };

    const handleImageError=()=>{
        setCardImage(defaultImage)
    }

    return (
        <div className="px-2 position-relative" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <div className={`my-cat-card border-0 p-3 mb-4 point ${selectedCategory && selectedCategory===slug ? 'selected-category':""}`}>
                <div className='cbody'>
                    <img onError={handleImageError} src={cardImage} className="image-container image m-auto" alt={categoryName} />
                    <div className='mt-auto p-2'>
                        <h5 className='text-bigliter'>{categoryName}</h5>
                        {isButtonShow &&
                            <div className='text-center mt-3'>
                            <button style={{fontSize:"12px"}} onClick={()=>navigate("/products?category="+slug)} className='btn-more p-2 px-3 rounded-2 text-bigliter'>{t("SeeProducts")}</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyComponent;
