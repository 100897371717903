import React, { useEffect, useState } from 'react';
import productDataEn from '../mockApi/products_en.json';
import productDataDe from '../mockApi/products_de.json';
import productDataHr from '../mockApi/products_hr.json';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const ProductDetail = () => {
  const { i18n } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  // Access specific query parameters
  const paramValue = query.get('category'); 

  useEffect(() => {
    
  }, [paramValue, filteredData]);

  useEffect(() => {
    if(paramValue){
      if (i18n.language === "en") {
        let data = productDataEn.filter((category) => category?.categorynameshort === paramValue);
        setFilteredData(data)
        // setFilteredData(productDataEn)
      } else if (i18n.language === "de") {
        // setFilteredData(productDataDe)
        let data = productDataDe.filter((category) => category?.categorynameshort === paramValue);
        setFilteredData(data)
      } else if (i18n.language === "hr") {
        // setFilteredData(productDataHr)
        let data = productDataHr.filter((category) => category?.categorynameshort === paramValue);
        setFilteredData(data)
      }
    }else{
      if (i18n.language === "en") {
        setFilteredData(productDataEn)
      } else if (i18n.language === "de") {
        setFilteredData(productDataDe)
      } else if (i18n.language === "hr") {
        setFilteredData(productDataHr)
      }
    }
  }, [i18n.language,paramValue]);

  return (
    <div>
      <div className="container mt-lg-5 mt-md-3 mt-3 mb-5">
        {filteredData?.length > 0 &&
          filteredData.map((category, cindex) => (
            category?.products?.length > 0 &&
            category.products.map((product, pindex) => (
              <div className={`row mycard rounded-4 shadow p-2 mt-5 ${pindex % 2 === 0 ? "flex-row-reverse" : ""}`} key={`${cindex}-${pindex}`}>
                <div className="col-md-6 col-12">
                    <div className="text-center">
                      <img
                        src={`/images/${category.imageFolderName}/${product?.image}`}
                        className="w-75"
                        alt={product?.title || 'Product'}
                      />
                    </div>
                </div>
                <div className="col-md-6 col-12 mt-lg-0 mt-md-3 mt-3">
                  <div>
                    <h3 className="text-colored font-inter text-bigliter">
                      {product?.title}
                    </h3>
                    <h4 className="font-inter text-colorblack font-16 product-subtitle">
                      {product?.subtitle}
                    </h4>
                    <p className="font-inter text-colorgray font-14 product-description">
                      {product?.description}
                    </p>                    
                  </div>
                </div>
              </div>
            ))
          ))}
      </div>
    </div>
  );
};

export default ProductDetail;
