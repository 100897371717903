import React from 'react'
import WindowCard from './WindowCard'
import { t } from 'i18next'

const Catalogue = () => {
  return (
    <div style={{
      backgroundColor: '#e5e1e1',
      padding: '10px',
    }}>
      <div className="container mt-5 ">
        <div className='text-center'>
          <br />
          <h3 className='font-inter text-bigliter text-colored'>{t("Catalogue")}</h3>

        </div>

        <WindowCard />
        <br />
        <br />
        <br />
        <br />
        <br />

      </div>
    </div>
  )
}

export default Catalogue