import React, { useEffect } from 'react';
import Navbar from '../component/Navbar';
import Productdetail from '../component/Productdetail';
import WindowCard from '../component/WindowCard';
import Footer from '../component/Footer';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const Product = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  // Access specific query parameters
  const paramValue = query.get('category'); 
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    navigate('/'); // Go back to the home page
  };

  return (
    <div>
      <Navbar />
      <div className="container" style={{ marginTop: '6em' }}>
        <button className='btn-white px-4 rounded-3 p-2' onClick={handleBack}>
          <FaArrowLeftLong className='me-2' />{t("Back")}
        </button>
        <h3 className='font-inter text-bigliter text-colored text-center font-45'>{t("Catalogue")}</h3>
        <WindowCard selectedCategory={paramValue}/>
      </div>
      <Productdetail />
      {/* <ContactUs /> */}
      <Footer />
    </div>
  );
};

export default Product;
