import React, { useState } from 'react';
import axios from 'axios';
import { t } from 'i18next';

const NewsletterSubsription = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      email,
    };

    try {
      await axios.post('https://api.brevo.com/v3/contacts', data, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': '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',
        },
      });
      alert('Subscription successful!');
    } catch (error) {
      console.error('Error subscribing:', error);
      alert('Subscription failed. Please try again.');
    }
  };

  return (
    <div class="input-group mb-3 border-0">
      <form onSubmit={handleSubmit} class="inline-form" >
        <input type="email" className="form-control" placeholder="E-Mail" aria-label="Recipient's mail" aria-describedby="basic-addon2" value={email}
          onChange={(e) => setEmail(e.target.value)} />
        <button className="input-group-text btn-signup" type="submit" id="basic-addon2">{t("Subscribe")}</button>
      </form>
    </div>
  );
};

export default NewsletterSubsription;