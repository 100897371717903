import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../carouselbanner.css'; // Create and import your custom CSS file
import CarouselBannerData from '../mockApi/landing-page/carouselbanner.json';

const CarouselBanner = ({ language }) => {
    const [currentText, setCurrentText] = useState(0);
    const texts = CarouselBannerData.map(item => item.caption[language]);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentText((prevText) => (prevText + 1) % texts.length);
      }, 6000); // Adjust the interval as needed
  
      return () => clearInterval(interval);
    }, [texts.length]);
  
    useEffect(() => {
      // Ensure the first text is not shown twice
      if (currentText === 0) {
        setCurrentText(1);
      }
    }, [currentText]);
  
    return (
      <OwlCarousel
        className="owl-theme"
        items={1}
        loop
        autoplay
        autoplayTimeout={24000} // Adjusted to fit the total animation time
        animateOut="fadeOut"
        animateIn="fadeIn"
      >
        <div className="item">
          <div className="banner">
            {texts.map((text, index) => (
              <div
                key={index}
                className={`text-line ${index === currentText ? 'show' : ''}`}
              >
                {text}
              </div>
            ))}
          </div>
        </div>
      </OwlCarousel>
    );
  };
  
  export default CarouselBanner;
