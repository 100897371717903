import React from 'react';
import {useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import testimonialData from '../mockApi/testimonials.json';
const SaytesTimonial = () => {
    const navigate = useNavigate();
    const settings2 = {
        dots: true,
        speed: 5000,
        autoplay: false,
        autoplaySpeed: 1500,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
        
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className=" col-12 px-0 Testimonial font-inter">
                        <Slider {...settings2}>
                        {testimonialData?.length>0
                        &&
                        testimonialData.map((val,index)=>{
                            return(
                                <div className=" px-2 " onClick={() => navigate('')}>
                                <div className="mycard border-0  p-3 mb-4  point h-100">
                                    <h6>{val?.message}</h6>
                                    <div className='d-flex align-items-center '>
                                        <div>
                                           <img src={`/images/testimonial/${val?.image}`} width={50} alt="" />
                                        </div>
                                     <div className='ms-2'>
                                     <h6 className='font-inter text-bigliter'>{val?.customername}</h6>
                                     <p className='font-inter font-14'>{val?.customerposition}</p>
                                     </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })

                        }
                            
                        </Slider>
                    </div>
                </div>
            </div>

        </>
    );
}

export default SaytesTimonial;
