import React from 'react'
// import fotterimg from '../assetr/rectangle.png';
import fotterlogo from '../assetr/fotterlogo.png';
// import facebook from '../assetr/facebook.png';
// import tweter from '../assetr/twitter.png';
// import linkden from '../assetr/linkedin.png';
// import piner from '../assetr/pinterest.png';
// import instagram from '../assetr/instagram.png';
import NewsletterSubsription from './NewsletterSubsription';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

const currentYear = new Date().getFullYear();
const Footer = () => {
  return (
    <div>
      <div className="container-fluid bg-colorfotter font-inter font-inter text-bigliter">

        <div className="container text-white p-5">
          <div className="row">

            <div className="col-lg-5 col-md-6 col-12">
              <br></br>
              <br></br>
              <br></br>
              <div><img src={fotterlogo} width={200} alt="" /></div>
              <br></br>
              <p className='mb-0 mt-4 copyright'>Copyright MANA GbR &copy; {currentYear} </p>
              <span className='mb-0 mt-4 copyright'>dev by <a className='dev' href='https://kdsoft.de' target='_blank' rel="noreferrer">kdsoft.de</a> </span>
              {/* <p className='mt-3'>{t("NewsletterText")}</p>
              <div className='d-flex'>
                <div><img src={facebook} width={30} alt="" /></div>
                <div className='mx-2'><img src={tweter} width={30} alt="" /></div>
                <div><img src={linkden} width={30} alt="" /></div>
                <div className='mx-2'><img src={piner} width={30} alt="" /></div>
                <div><img src={instagram} width={30} alt="" /></div>


              </div> */}
            </div>
            <div className="col-lg-2 col-md-6 col-12 mt-lg-0 mt-md-3 mt-5">
              <h5 className='mb-4'> <span className='bottomline'>{t("Menu")}</span> </h5>
              <Link to={'/?goto=home'}><p className='mb-0'>{t("Home")}</p></Link>
              <Link to={'/?goto=about'}><p className='mb-0 mt-2'>{t("AboutUs")}</p></Link>
              <Link to={'/products'}><p className='mb-0 mt-2'>{t("Catalogue")}</p></Link>

              {/* <Link to={'/?goto=testimonial'}><p className='mb-0 mt-2'>{t("Testimonial")}</p></Link> */}
              <Link to={'/?goto=contact'}><p className='mb-0 mt-2'>{t("ContactUs")}</p></Link>
              <Link to={'/impressum'}><p className='mb-0 mt-2'>{t("Impressum")}</p></Link>
            </div>
            <div className="col-lg-4 col-md-6  col-12 mt-lg-0 mt-md-3 mt-5">

              <h5 className='mb-4'><span className='bottomline'>{t("Newsletter")}</span></h5>
              <p className='mt-3'>{t("NewsletterText")}</p>              
              <NewsletterSubsription />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer