// MyBusiness.js
import React from 'react';
import { Helmet } from 'react-helmet';

const MyBusiness = () => (
  <Helmet>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "MANA Import Export",
          "image": "https://www.mana-ie.de/static/media/navlogo.1dce33fdb31a6f3cc85e.png",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Schwarzerlenweg 29",
            "addressLocality": "Frankfurt am Main",
            "addressRegion": "Hessen",
            "postalCode": "65933",
            "addressCountry": "Germany"
          },
          "telephone": "+49 172 9362262",
          "url": "https://mana-ie.de"
        }
      `}
    </script>
  </Helmet>
);

export default MyBusiness;
