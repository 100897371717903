import React, { useState } from 'react'
import emailImage from '../assetr/email.png';
import phone from '../assetr/phone.png';
// import address from '../assetr/adress.png';
import { t } from 'i18next';
import axios from 'axios';

const ContactUs = () => {
  // State variables for form fields
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const data = JSON.stringify({
      email: email,
      name: name,
      number: number,
      subject: 'New Contact Message',
      body: message
    });

    const config = {
      method: 'post',
      url: 'https://civitemail.canctests.com/',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    try {
      await axios.request(config);
      setSuccess('Your message has been sent successfully!');
      // Reset form fields
      setName('');
      setEmail('');
      setNumber('');
      setMessage('');
      alert("Your message has been sent successfully!")
    } catch (error) {
      setError('There was an error sending your message. Please try again.');
      console.error('Error sending message:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id='contact'>
      <div className="container-fluid mt-5 bg-contactus p-lg-0 p-md-3 p-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <h3 className='font-inter text-colored font-45 fw-600 text-bigliter'>{t("ContactUs")}</h3>
                <p className='font-inter'>{t("ContactUsText")}
                </p>
              </div>
              <div className='d-flex'>
                <div><img src={emailImage} width={40} alt="" /></div>
                <div className='ms-2'>
                  <h6 className='font-inter'>{t("Email")}</h6>
                  <p className='font-inter'>info@mana-ie.de</p>
                </div>
              </div>
              <div className='d-flex'>
                <div><img src={phone} width={40} alt="" /></div>
                <div className='ms-2'>
                  <h6 className='font-inter'>{t("Phone")}</h6>
                  <p><span className='font-inter'>+49 172 9362862</span><br />
                  <span className='font-inter'>+49 172 9362262</span></p>
                </div>
              </div>
              {/* <div className='d-flex'>
                <div><img src={address} width={40} alt="" /></div>
                <div className='ms-2'>
                  <h6 className='font-inter'>{t("Address")}</h6>
                  <p className='font-inter'>{t("AddressText")}</p>
                </div>
              </div> */}
            </div>
              <div className="col-md-6">
            <form onSubmit={handleSubmit}>
                <div className='bg-colorblack rounded-3 p-3'>
                  <div className="mb-3">
                    <label htmlFor="nameInput" className="form-label">{t("Name")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nameInput"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder={t("YourName")}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="emailInput" className="form-label">{t("Email")}</label>
                    <input
                      type="email"
                      className="form-control"
                      id="emailInput"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t("YourEmail")}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="numberInput" className="form-label">{t("YourNumber")}</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="numberInput"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      placeholder={t("YourNumber")}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="messageInput" className="form-label">{t("Message")}</label>
                    <textarea
                      className="form-control"
                      id="messageInput"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder={t('EnterYourMessage')}
                      rows="3"
                      required
                    ></textarea>
                  </div>
                  <div>
                    <button type="submit" className='btn-more p-2 rounded-2 w-100 text-bigliter' disabled={loading}>
                      {loading ? t('Sending') : t('Send Message')}
                    </button>
                  </div>
                  {error && <p className="text-danger">{error}</p>}
                  {success && <p className="text-success">{success}</p>}
                </div>
            </form>
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs